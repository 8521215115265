<template>
<div class = "vod-live">
  <div class ="player-box">
    <div class ="qplayer_wrapper" style="display:block;overflow: hidden; z-index: 100000;  left: 0px; top: 0px; width: 100%; height: 100%;">
    <!-- <b-button variant="primary" v-if="isMuted" @click="toggleMute" class="overlay-button" style="position: absolute; top: 10px; left: 35%; z-index: 999;">
      탭 하여 음소거 해제
    </b-button> -->
    <!-- class="video-component"  -->
    <!-- <video ref="video" playsinline width="100%" controls v-on:play="onPlay" id="video" style="width:100%; height:100%; display:block; z-index:999;" webkit-playsinline="" autoplay="true" ></video> -->
     <video ref="video" playsinline width="100%" controls v-on:play="onPlay" id="video" style="width:100%; height:100%; display:block; z-index:999;" webkit-playsinline="" autoplay="true" ></video> 
  </div>
</div>
</div>

</template>

<script>
import '@/styles/style.css';
import Hls from 'hls.js';
export default {
  props: {
    isPause: {
      type: Boolean,
      default: true
    },
    streamingUrl:{
      type: String,
    }
    },
  data() {
    return {
      isMuted:true
    }
  },
  watch: {
    streamingUrl() {
      this.playVideo()
    },
    isPause(){
      let video = this.$refs.video;
      //video.muted = this.isMuted;
      if(this.isPause){
        video.pause()
      }
    }
  },
  mounted() {
    this.playVideo()
  },
  methods: {
    onPlay() {
      this.isPause = false
      this.$emit("onPlay");
      let video = this.$refs.video;
      if (video) {
        video.currentTime = video.duration; // 최신 시점으로 이동
      }
    },
    toggleMute() {
      let video = this.$refs.video;
      // Toggle mute status
      this.isMuted = !this.isMuted;
      // Update the video's mute property
      video.muted = this.isMuted;
    },
    playVideo() {
      if(this.streamingUrl === '') return
      let video = this.$refs["video"];
      //let stream = "https://livevod.hnsmall.com:2935/live/mp4:a.stream/playlist.m3u8";
      let stream = this.streamingUrl

      // 자동재생할려면 mute해야함
      video.muted = this.isMuted
      if (Hls.isSupported()) {
        let hls = new Hls();
        hls.loadSource(stream);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
      });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // 아이폰인경우에 hls 재생못할경우 처리
        video.src = stream;
        video.play();
      }
    }
  }
}
</script>

<style>
/* 탐색바만 숨기기 */
video::-webkit-media-controls-timeline {
  display: none !important;
}
video::-webkit-media-controls-seek-back-button,
video::-webkit-media-controls-seek-forward-button {
  display: none !important;
}
/*
.video-component {
  border-radius: 5px 5px 5px 5px;
}
.overlay-button {
  position: absolute;
  top: 10px;
  left: 35%;
  z-index: 1;
}
*/
</style>